import React from 'react'
import styled from 'styled-components'
import Logo from '../../../Assets/Logos/Voyajee logo white-01.png'
import { Link } from 'react-router-dom'
import { AppStoreButton, GooglePlayButton } from '../../../Components/Buttons/MobileAppButtons'
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';

const FooterContainer = styled.div`
    width: 100%;
    height: auto;
    /* background-color: linear-gradient(180deg, #FEDE59 0%, #E4600A 100%); */
    /* background: linear-gradient(to bottom, #ffffff 0%, #E4600ABD 75%); */
    background: linear-gradient(to top, #FEDE59 0%, #F79051 100%);
    /* background-color: #F35C3D; */
    /* background-color: #b74831; */
    /* background-color: #fcefe6; */
    display: flex;
    flex-direction: column;
    padding: 0 6vw;

    @media screen and (max-width: 810px) {
        padding: 0 3vw ;
    }
`

const Divider = styled.div`
    width: 90%;
    height: 1px;
    background-color: #e6e6e6;
    margin: 0 auto;
    margin-top: 30px;
    `

const FooterContentContainer = styled.div`
    width: auto;
    height: auto;
    margin-top: 30px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    div:has( .homeFooterLogo ) {
        @media screen and (max-width: 768px) {
            width: 100%;
            max-width: none;
        }
    }
`

const ContentContainer = styled.div`
    width: ${({ w }) => w ? w : 'auto'};
    max-width: 350px;
    min-width: 200px;
    height: auto;
    display: flex;
    flex-direction: ${({ flexD }) => flexD};
    align-items: center;

    /* border: 1px solid white; */

    img.homeFooterLogo {
        width: 100%;
        max-width: 100px;
        height: 100%;
        object-fit: contain;
        display: block;
        margin:  auto;

		/* border: 1px solid black; */
    }

    div.buttonCont {
        width: 100%;
        height: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        padding: 20px 0;

        gap: 10px 30px;

    }

    div.socialsCont {
        padding: 20px 0;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        .socialIcon {
            font-size: 40px;
            margin: 0 10px;
            color: white;
            transition: 0.3s cubic-bezier(.42,.35,.21,1);

            &:hover {
                color: #E4600A;
                scale: 1.1;
                cursor: pointer;
                
            }
        }
        
    }

    @media screen and (max-width: 768px) {
        width: 100%;
        max-width: none;
        min-width: none;
        margin-top: 20px;      
    }

    
`

const CaptionTitle = styled.h3`
	width: 100%;
	height: 30px;
	font-family: 'Lato';
	font-style: normal;
	font-weight: 700;
	font-size: 22px;
	line-height: 30px;
	color: #FFFFFF;

	/* border: 1px solid white; */	
`

const Caption = styled.p`
	width: 100%;
	height: auto;
    font-family: 'Roboto', sans-serif;
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 24px;
    word-spacing: 2px;
	color: #FFFFFF;
    padding-top: 10px;

	/* border: 1px solid white; */
`

const LinkContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 0 30px;
    
    /* border: 1px solid white; */

    h4 {
        font-family: 'Roboto', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 24px;
        color: #FFFFFF;
        display: inline-block;
    }

    ul {
        margin-top: 10px;
        padding-left: 20px;
        list-style: none;
    }

    a {
        font-family: 'Roboto', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        color: #FFFFFF;
    }
`

const Button = styled.button`
    padding: 7px 20px;
    color: white;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    border-radius: 50px;
    margin-left: 20px;
    border: 1px solid white;
    transition: 0.3s cubic-bezier(.42,.35,.21,1);

    &:hover {
        border-color: #E4600A;
        background-color: #E4600A;
        color: white;
        scale: 1.03;
        cursor: pointer;
    }

    &:active {
        border-color: #E4600A;
        background-color: #E4600A;
        color: white;
        scale: 0.98;
        transition: 0.1s;
    }
`

const CopyrightsContainer = styled.div`
    width: auto;
    margin: 10px auto;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 7px 60px ;
    border-radius: 50px;

    border: 1px solid white;

    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    color: #FFFFFF;
`


const Footer = () => {
    return (
        <FooterContainer>
            <Divider />
            <FooterContentContainer>
                <ContentContainer flexD="column" w="auto">
                    <img src={Logo} alt="LogoNavbar" className='homeFooterLogo' />
                </ContentContainer>
                <ContentContainer flexD="column">
                    <CaptionTitle>
                        Your Journey, Your Way.
                    </CaptionTitle>
                    <Caption>
                        Discover, Book, and Experience Sri Lanka with Ease. Voyajee is your ultimate travel companion for exploring Sri Lanka. From finding hidden gems to booking local tours, our app offers everything you need to create unforgettable travel experiences. Explore top destinations, connect with expert guides, and make the most of your adventure—all in one place.
                    </Caption>
                </ContentContainer>
                <ContentContainer flexD="row">
                    <LinkContainer>
                        <h4>Menu</h4>
                        <ul>
                            <li><Link to="#">Home</Link></li>
                            <li><Link to="#">About Us</Link></li>
                            <li><Link to="#">Documentation</Link></li>
                            <li><Link to="#">Privacy Policy</Link></li>
                            <li><Link to="#">Terms & Conditions</Link></li>
                        </ul>
                    </LinkContainer>
                    <LinkContainer>
                        <h4>Contact Us</h4>
                        <ul>
                            <li><Link to="#">Email</Link></li>
                            <li><Link to="#">Phone</Link></li>
                            <li><Link to="#">Address</Link></li>
                        </ul>
                    </LinkContainer>
                </ContentContainer>
                <ContentContainer flexD="column" >
                    <div className='buttonCont'>
                        <GooglePlayButton />
                        <AppStoreButton />
                    </div>
                    <div className='socialsCont'>
                        <FacebookIcon className='socialIcon' />
                        <TwitterIcon className='socialIcon' />
                        <InstagramIcon className='socialIcon' />
                        <Button>Become a guide</Button>
                    </div>
                </ContentContainer>
            </FooterContentContainer>
            <CopyrightsContainer>
                ©2024 Developed by Quantumisle IT Department.
            </CopyrightsContainer>
        </FooterContainer>
    )
}

export default Footer