import React from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import './App.css';
import Home from './Pages/Home/Home.js';
import ErrorBoundary from './Pages/ErrorBoundary';


const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "*",
    element: <ErrorBoundary />,
  },
])
function App() {
  return (
    <RouterProvider router={router} />
  );
}

export default App;
