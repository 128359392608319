import React from 'react'
import styled from 'styled-components'
import Navbar from './Components/Navbar'
import Header from './Components/Header'
import {Intro, WhyChooseUs, Divider} from './Components/Intro'
import Destinations from './Components/Destinations'
import Benefits from './Components/Benefits'
import Footer from './Components/Footer'
import JoinGuides from './Components/JoinGuides'

const Container = styled.div`
    overflow-x: hidden;
`
const Home = () => {
  return (
    <Container>
        <Navbar />
        <Header />
        <Intro />
        <Divider />
        <WhyChooseUs />
        <Divider />
        <Destinations />
        <Divider />
        <Benefits />
        <Divider />
        <JoinGuides />
        <Footer />
    </Container>
  )
}

export default Home