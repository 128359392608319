import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

const ErrorBoundaryCont = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100%;
    color: #F79352;
`
const Title = styled.h1`
    font-size: 3em;
    text-align: center;
    margin: 30px 0;
    font-family: 'Roboto', sans-serif;
`
const Text = styled.p`
    font-size: 2em;
    text-align: center;
    margin: 0 0 30px 0;
    font-family: 'Lato', sans-serif;
`

const Button = styled.button`
    width: 200px;
    height: 50px;
    margin-top: 30px;
    background-color: #F79352;
    color: #282c34;
    border: none;
    font-size: 1.5em;
    font-family: 'Roboto', sans-serif;
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.3s ease;

    &:hover {
        color: white;
        scale: 1.04;
        box-shadow: 0 0 10px #F79352;
    }
`
const ErrorBoundary = () => {
  return (
    <ErrorBoundaryCont>
      <Title>Error 404! - Page Not Found</Title>
      <Text>We are still under construction.</Text>
      <Text>Big Updates Coming Soon!</Text>
      <Link to="/"><Button>Back to Home</Button></Link>
    </ErrorBoundaryCont>
  )
}

export default ErrorBoundary