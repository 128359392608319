import React, { useEffect } from 'react'
import { useMediaQuery } from 'react-responsive'
import styled from 'styled-components'

import Logo from '../../../Assets/Images/Voyajee icon only-01.png'
import { Link } from 'react-router-dom'

const DesktopNavbarContainer = styled.nav`
    width: 100%;
    height: 65px;
    background-color: white;
    display: flex;
    justify-content: space-around;
    align-items: center;

    position: absolute;
    z-index: 999;
    top: 0;
    left: 0;

    .w30 {
        width: 30%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .w40 {
        width: 40%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    img {
        position: relative;
        height: 80%;
        width: 30%;
        width: auto;
        display: block;
    }

    ul {
        width: 40%;
        height: 100%;
        display: flex;
        list-style: none;
        flex-direction: row;
        gap: 40px;
        margin-left: 20px;
        

        li {
            font-family: 'Roboto', sans-serif;
            font-size: 14px;
            font-weight: 500;
            text-transform: uppercase;
            color: #F79352;
            cursor: pointer;
            
            a {
                text-decoration: none;
                color: #E4600A;

            }
        }
    }


        
    button {
        all: unset;
        width: 150px;
        height: 35px;
        padding: 0 10px;
        color: #E4600A;
        font-family: 'Roboto', sans-serif;
        font-size: 14px;
        font-weight: 500;
        text-transform: uppercase;
        border: 2px solid #E4600A;
        border-radius: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
`


const DesktopNavbar = () => {
    return (
        <DesktopNavbarContainer>
            <div className='w30'><img src={Logo} alt="LogoNavbar" /></div>
            <ul className='w40'>
                <li><Link to="#">Home</Link></li>
                <li><Link to="#">About</Link></li>
                <li><Link to="#">Contact Us</Link></li>
                <li><Link to="#">Download App</Link></li>
            </ul>
            <div className='w30'><button>Become a guide</button></div>
        </DesktopNavbarContainer>
    )
}


const MobileNavbarContainer = styled.nav`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 65px;
    background-color: white;
    display: flex;
    justify-content: space-around;
    align-items: center;

    img {
        position: relative;
        height: 80%;
        width: auto;
        display: block;
    }
`
const MobileNavbar = () => {
    return (
        <MobileNavbarContainer>
            <img src={Logo} alt="LogoNavbar" />
        </MobileNavbarContainer>
    )
}

const Navbar = () => {
    const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
    const overflowReset = () => {
        const rootElement = document.documentElement;
        if (rootElement.style.overflow === 'hidden') {
            rootElement.style.overflow = 'auto';
        }
    };

    useEffect(() => {
        overflowReset();
    }, []);
    
    return (
        <div>
            {isMobile ? <MobileNavbar /> : <DesktopNavbar />}
        </div>
    )
}

export default Navbar