import React, { useEffect, useRef } from 'react'
import styled from 'styled-components'
import sigiriyaImg from '../../../Assets/Images/sigiriya.png'
import galleImg from '../../../Assets/Images/galleImg.png'
import { Rating } from '@mui/material'

const Container = styled.div`
    width: 100%;
    height: auto;
    margin-top: 80px;
    padding: 0 6vw;
    display: flex;
    flex-direction: column;
    align-items: center;
`

const DestinationsTitle = styled.h1`
    font-size: 40px;
    font-weight: 500;
    color: black;
    letter-spacing: -1px;
    font-family: 'Roboto', sans-serif;

    @media screen and (max-width: 768px) {
        text-align: center;
        font-size: 35px;
    }
`

const DestinationsCaption = styled.p`
    font-size: 16px;
    font-weight: 300;
    color: black;
    margin-top: 20px;
    font-family: 'Roboto', sans-serif;

    @media screen and (max-width: 768px) {
        text-align: center;
        font-size: 14px;
        line-height: 24px;
    }
`

const CardsContainer = styled.div`
    width: 100%;
    height: auto;
    margin: 40px 0;
    padding: 30px 0;
    display: flex;
    gap: 20px;
    overflow-x: scroll;

    &::-webkit-scrollbar {
        width: 7px;
        height: 7px;
        background-color: transparent;
        border-radius: 1px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: transparent;
        border-radius: 10px;
    }

    &:hover::-webkit-scrollbar-thumb {
        background-color: #E4600A80;
    }

    @media screen and (max-width: 768px) {
        margin-top: 20px;
        gap: 10px;
        scroll-snap-type: x mandatory;
    }
`

const Card = ({ ...props }) => {

    const CardCont = styled.div`
        min-height: 450px;
        min-width: 350px;
        border-radius: 20px;
        border: 1px solid #E6E6E6;

        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 10px;
        background-color: white;

        transition: 0.3s cubic-bezier(.42,.35,.21,1);

        &:hover {
            border: 1px solid white;
            scale: 1.03;
            box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
        }

        user-select: none;

        @media screen and (max-width: 768px) {
            position: relative;
            scroll-snap-align: center;
            min-width: 99%;
            &:hover {
                border: 1px solid #E6E6E6;
                scale: 1;
                box-shadow: none;
            }
        }
    `

    const CardImg = styled.img`
        width: 100%;
        height: 175px;
        object-fit: cover;
        border-radius: 12px;
    `

    const CardCaption = styled.p`
        margin-top: 5px;
        font-size: 13px;
        color: #454545BF;
        text-align: left;
        font-family: 'Inter', sans-serif;
        font-weight: 400;
        line-height: 18px;
        letter-spacing: -0.5px;
        text-align: center;
    `

    const CardTitle = styled.h3`
        margin-top: 7px;
        font-size: 20px;
        color: black;
        text-align: left;
        font-family: 'Inter', sans-serif;
        font-weight: 600;
    `

    const CardRating = styled.p`
        font-size: 12px;
        color: #45454580;
        text-align: center;
        font-weight: 400;
        font-family: 'Inter', sans-serif;

        display: flex;
        align-items: center;
        gap: 5px;
    `

    const CardInfo = styled.p`
        width: 100%;
        padding: 0 10px;
        margin-top: 15px;
        font-size: 13px;
        color: #454545BF;
        font-family: 'Inter', sans-serif;
        font-weight: 400;

        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 5;
        -webkit-box-orient: vertical;
    `

    const Button = styled.button`
        all: unset;
        width: 200px;
        height: 30px;
        padding: 0 10px;
        margin-top: 15px;
        margin-bottom: 5px;
        
        color: #E4600A;
        border: 1px solid #E4600A;
        border-radius: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;
        font-family: 'Roboto', sans-serif;
        font-size: 14px;
        cursor: pointer;

        transition: 0.15s cubic-bezier(.42,.35,.21,1);

        ${CardCont}:hover & {
            scale: 1.03;
            background-color: #E4600A;
            color: white;
        }

        ${CardCont}:active & {
            scale: 0.98;
        }
    `
    return (
        <CardCont>
            <CardImg src={props.img} />
            <CardCaption>
                {props.caption}
                <br />
                {props.price}
            </CardCaption>
            <CardTitle>{props.destination}</CardTitle>
            <CardRating>
                <Rating name="read-only" value={props.value} precision={0.5} size="small" readOnly />
                {props.rating}
            </CardRating>
            <CardInfo>
                {props.info}
            </CardInfo>
            <Button>Book Now</Button>
        </CardCont>
    )
}

const useCardsContainerScroll = () => {
    const cardsContainerRef = useRef(null);

    useEffect(() => {
        const cardsContainer = cardsContainerRef.current;

        let isDown = false;
        let startX;
        let scrollLeft;

        const handleMouseDown = (e) => {
            isDown = true;
            startX = e.pageX - cardsContainer.offsetLeft;
            scrollLeft = cardsContainer.scrollLeft;
        };

        const handleMouseLeave = () => {
            isDown = false;
        };

        const handleMouseUp = () => {
            isDown = false;
        };

        const handleMouseMove = (e) => {
            if (!isDown) return;
            e.preventDefault();
            const x = e.pageX - cardsContainer.offsetLeft;
            const walk = (x - startX) * 1; //adjust the speed of the scroll
            cardsContainer.scrollLeft = scrollLeft - walk;
        };

        cardsContainer.addEventListener('mousedown', handleMouseDown);
        cardsContainer.addEventListener('mouseleave', handleMouseLeave);
        cardsContainer.addEventListener('mouseup', handleMouseUp);
        cardsContainer.addEventListener('mousemove', handleMouseMove);

        return () => {
            cardsContainer.removeEventListener('mousedown', handleMouseDown);
            cardsContainer.removeEventListener('mouseleave', handleMouseLeave);
            cardsContainer.removeEventListener('mouseup', handleMouseUp);
            cardsContainer.removeEventListener('mousemove', handleMouseMove);
        };
    }, []);

    return {
        cardsContainerRef,
    };
};

const Destinations = () => {
    const { cardsContainerRef } = useCardsContainerScroll();

    return (
        <Container>
            <DestinationsTitle>
                Explore Sri Lanka's Must-See Destinations
            </DestinationsTitle>
            <DestinationsCaption>
                Discover the top attractions that make Sri Lanka a traveler's paradise. From ancient ruins to stunning beaches, explore the best places that define the island's charm
            </DestinationsCaption>
            <CardsContainer
                ref={cardsContainerRef}
                className='cardsContainer'
            >
                <Card
                    img={sigiriyaImg}
                    caption="Sigiriya Sunrise Climb Package"
                    price="$75 per person"
                    destination="Sigiriya"
                    value={4}
                    rating="(4.5/5 based on 1,200 reviews)"
                    info="Sigiriya or Sinhagiri is an ancient rock fortress located in the northern Matale District near the town of Dambulla in the Central Province, Sri Lanka. It’s famous for its massive rock column and historic frescoes, making it a UNESCO World Heritage site and a must-visit landmark. Experience the sunrise from the top of Sigiriya Rock. This package includes a guided tour, entrance fees, and a complimentary breakfast at the summit."
                />
                <Card
                    img={galleImg}
                    caption="Galle Fort Heritage Walk"
                    price="$50 per person"
                    destination="Galle Fort"
                    value={4.8}
                    rating="(4.8/5 based on 950 reviews)"
                    info="Galle Fort is a historic fortification built by the Portuguese and later fortified by the Dutch in the 17th century. Located in the coastal city of Galle, it is a UNESCO World Heritage site known for its cobblestone streets, colonial architecture, and panoramic views of the Indian Ocean. Take a guided walk through Galle Fort and uncover its colonial past. This package includes a professional guide, museum visits, and a tea break at a traditional café within the fort."
                />
                <Card
                    img={sigiriyaImg}
                    caption="Sigiriya Sunrise Climb Package"
                    price="$75 per person"
                    destination="Sigiriya"
                    value={4.5}
                    rating="(4.5/5 based on 1,200 reviews)"
                    info="Sigiriya or Sinhagiri is an ancient rock fortress located in the northern Matale District near the town of Dambulla in the Central Province, Sri Lanka. It’s famous for its massive rock column and historic frescoes, making it a UNESCO World Heritage site and a must-visit landmark. Experience the sunrise from the top of Sigiriya Rock. This package includes a guided tour, entrance fees, and a complimentary breakfast at the summit."
                />
                <Card
                    img={sigiriyaImg}
                    caption="Sigiriya Sunrise Climb Package"
                    price="$75 per person"
                    destination="Sigiriya"
                    value={4.5}
                    rating="(4.5/5 based on 1,200 reviews)"
                    info="Sigiriya or Sinhagiri is an ancient rock fortress located in the northern Matale District near the town of Dambulla in the Central Province, Sri Lanka. It’s famous for its massive rock column and historic frescoes, making it a UNESCO World Heritage site and a must-visit landmark. Experience the sunrise from the top of Sigiriya Rock. This package includes a guided tour, entrance fees, and a complimentary breakfast at the summit."
                />
                <Card
                    img={sigiriyaImg}
                    caption="Sigiriya Sunrise Climb Package"
                    price="$75 per person"
                    destination="Sigiriya"
                    value={4.5}
                    rating="(4.5/5 based on 1,200 reviews)"
                    info="Sigiriya or Sinhagiri is an ancient rock fortress located in the northern Matale District near the town of Dambulla in the Central Province, Sri Lanka. It’s famous for its massive rock column and historic frescoes, making it a UNESCO World Heritage site and a must-visit landmark. Experience the sunrise from the top of Sigiriya Rock. This package includes a guided tour, entrance fees, and a complimentary breakfast at the summit."
                />
            </CardsContainer>
        </Container>
    )
}

export default Destinations