import React from 'react'
import styled from 'styled-components'

const Container = styled.div`
    width: 100%;
    height: auto;
    margin-top: 60px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`

const BenefitsTitle = styled.h1`
    font-size: 40px;
    font-weight: 500;
    color: black;
    letter-spacing: -1px;
    font-family: 'Roboto', sans-serif;

    @media screen and (max-width: 768px) {
        text-align: center;
        font-size: 35px;
    }
`

const BenefitsCaption = styled.p`
    width: 80%;
    font-size: 16px;
    font-weight: 300;
    color: black;
    font-family: 'Roboto', sans-serif;
    line-height: 32px;
    text-align: center;
    margin: 30px auto;

    @media screen and (max-width: 768px) {
        width: 100%;
        text-align: center;
        font-size: 14px;
        line-height: 24px;
    }
    
`

const BenefitsGrid = styled.div`
    margin-top: 20px;
    width: 100%;
    max-width: 1200px;
    height: auto;
    display: grid;
    padding: 40px;
    grid-template-columns: repeat(3, 1fr);
    justify-content: space-between;
    gap: 40px;

    @media screen and (max-width: 1000px) {
        grid-template-columns: repeat(2, 1fr);
    }

    
    @media screen and (max-width: 768px) {
        margin-top: 20px;
        width: 100%;
        grid-template-columns: repeat(2, 1fr);
        gap: 20px;
    }
    
    @media screen and (max-width: 650px) {
        grid-template-columns: repeat(1, 1fr);
        gap: 20px;
        padding: 20px;
    }
    
`


const BenefitCard = ({...props}) => {

    const CardCont = styled.div`
        width: 100%;
        min-width: 260px;
        height: 200px;
        border-radius: 20px;
        border: 1px solid #E6E6E6;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 20px;
        background-color: white;
        transition: 0.3s cubic-bezier(.42,.35,.21,1);
        
        &:hover {
            border: 1px solid #E4600A;
            scale: 1.03;
            background-color: #E4600A1A;
        }

        @media screen and (max-width: 768px) {
            height: 180px;
            min-width: none;
        }
    `

    const CardTitle = styled.h1`
        font-size: 24px;
        font-weight: 500;
        color: #E4600A;
        letter-spacing: -0.5px;
        text-align: center;
        font-family: 'Roboto', sans-serif;

        @media screen and (max-width: 768px) {
            text-align: center;
            font-size: 20px;
        }
    `

    const CardCaption = styled.p`
        font-size: 16px;
        font-weight: 400;
        color: #454545;
        font-family: 'Roboto', sans-serif;
        line-height: 32px;
        text-align: center;
        margin-top: 20px;

        @media screen and (max-width: 768px) {
            font-size: 14px;
            line-height: 24px;
        }
    `
    return (
        <CardCont>
            <CardTitle>{props.title}</CardTitle>
            <CardCaption>{props.caption}</CardCaption>
        </CardCont>
    )
}

const Benefits = () => {
    return (
        <Container>
            <BenefitsTitle>Exclusive Benefits!</BenefitsTitle>
            <BenefitsCaption>
                Discover handpicked destinations and hidden gems, book tours and activities instantly,
                and connect with local guides who offer authentic insights and personalized experiences.
                Whether you're seeking cultural immersion, beach relaxation, or wildlife adventures,
                Voyajee has something for every traveler.
            </BenefitsCaption>
            <BenefitsGrid>
                <BenefitCard
                    title='Instant Booking'
                    caption='Secure and easy bookings for tours, excursions, and accommodations. '
                />
                <BenefitCard
                    title='Personalized Recommendations'
                    caption='Tailored experiences based on your preferences and interests. '
                />
                <BenefitCard
                    title='Local Expertise'
                    caption='Access to trusted guides and local experts for an authentic journey. '
                />
                <BenefitCard
                    title='Interactive Maps'
                    caption='Find and navigate to nearby attractions and activities effortlessly. Flexible '
                />
                <BenefitCard
                    title='Itineraries'
                    caption='Plan, customize, and save your travel plans in one convenient place. User '
                />
                <BenefitCard
                    title='Reviews'
                    caption='Real feedback from other travelers to help you choose the best experiences.'
                />
            </BenefitsGrid>
        </Container>
    )
}

export default Benefits