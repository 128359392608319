import React from 'react'
import styled from 'styled-components';
import { GooglePlayButton, AppStoreButton } from '../../../Components/Buttons/MobileAppButtons';
import headerImg from '../../../Assets/Images/headerImg.svg';

const HeaderCont = styled.header`
	width: 100%;
	height: calc(100svh - 65px);
	background-color: #FEDE5933;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 65px;

	@media screen and (max-width: 768px) {
		height: auto;
		flex-direction: column;
	}
`

const Width50 = styled.div`
    width: 50%;
    height: 100%;
    padding-left: 80px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media screen and (max-width: 768px) {
		height: auto;
		width: 100%;
		padding-left: 0px;
		padding: 0 20px;
    }

    img.homeHeaderImg {
		width: 100%;
		height: 100%;
		object-fit: contain;
		display: block;
    }
`

const HeaderTitle = styled.h1`
	font-size: 64px;
	color: #FF8637;
	font-weight: 300;
	font-family: "Great Vibes", serif;

	@media screen and (max-width: 768px) {
		margin-top: 30px;
		font-size: 50px;
		text-align: center;
	}

`

const SubText1 = styled.p`
	font-size: 20px;
	color: #FF8637;
	font-style: italic;
	margin-top: 40px;
	font-family: "Exo 2", sans-serif;

	@media screen and (max-width: 768px) {
		margin-top: 20px;
		text-align: center;
		font-size: 18px;
	}
`

const SubText2 = styled.p`
	font-size: 18px;
	color: #FF8637;
	line-height: 25px;
	font-style: italic;
	margin-top: 20px;
	font-family: "Exo 2", sans-serif;
	font-weight: 300;

	@media screen and (max-width: 768px) {
		text-align: justify;
		font-size: 16px;
	}
`

const ButtonContainer = styled.div`
	display: flex;
	gap: 30px;
	align-items: center;
	margin-top: 30px;

	@media screen and (max-width: 768px) {
		width: 100%;
		justify-content: center;
		margin-bottom: 30px;
	}
`

const Header = () => {

	return (
		<HeaderCont>
			<Width50>
				<HeaderTitle>Discover Sri Lanka<br />Like Never Before</HeaderTitle>
				<SubText1>Your Ultimate Travel Companion to<br />
					Explore, Book, and Experience the Best Destinations.</SubText1>
				<SubText2>Voyajee brings the wonders of Sri Lanka right to your fingertips. From hidden gems to iconic landmarks, plan your adventures effortlessly with our curated guides, interactive maps, and instant booking features. Embark on unforgettable experiences and create lasting memories.</SubText2>
				<ButtonContainer>
					<GooglePlayButton />
					<AppStoreButton />
				</ButtonContainer>
			</Width50>
			<Width50>
				<img src={headerImg} alt="headerImg" className='homeHeaderImg' />
			</Width50>
		</HeaderCont>
	)
}

export default Header