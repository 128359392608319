import React from 'react'
import styled from 'styled-components'

import JoinGuidesImg from '../../../Assets/Images/joinUsImg.png'
import JoinUsIcon from '../../../Assets/Icons/joinUsIcon.svg'
import ArrowOutwardRoundedIcon from '@mui/icons-material/ArrowOutwardRounded';

const Container = styled.div`
    width: 100%;
    height: auto;

    padding: 0 6vw;
    margin: 40px 0;

    @media screen and (max-width: 768px) {
        padding: 0 10px;
    }
`

const JoinGuidesTitle = styled.h1`
    font-size: 40px;
    font-weight: 500;
    color: black;
    letter-spacing: -1px;
    text-align: center;
    font-family: 'Roboto', sans-serif;

    @media screen and (max-width: 768px) {
        text-align: center;
        font-size: 35px;
    }
`

const JoinGuidesCaption = styled.p`
    margin-top: 20px;
    font-size: 16px;
    color: black;
    text-align: center;
    font-family: 'Roboto', sans-serif;
    font-weight: 300;
    line-height: 32px;

    @media screen and (max-width: 768px) {  
        font-size: 16px;
        line-height: 28px;
    }
`

const Image = styled.div`
    width: 90%;
    max-width: 500px;
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 30px auto;

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        display: block;
        border-radius: 10px;
        border: 1px solid #e6e6e6;
        box-shadow: 0px 0px 20px #E4600A80;
    }
`

const JoinGuidesFeatures = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
`

const FeaturesCont = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: row;
    margin: 0 20px;
`

const FeaturesIcon = styled.div`
    width: 70px;
    aspect-ratio: 1;
    display: flex;
    justify-content: center;
    align-items: center;
`

const FeaturesInfo = styled.p`
    width: auto;
    height: 100%;
    font-family: 'Roboto', sans-serif;
    padding-left: 20px;

    display: flex;
    flex-direction: column;
    justify-content: start;

    
    h4 {
        width: 100%;
        font-size: 16px;
        font-weight: 500;
    }
    
    p {
        width: 100%;
        font-size: 14px;
        font-weight: 300;
        letter-spacing: 0.25px;
        line-height: 22px;
    }

    @media screen and (max-width: 768px) {  
        font-size: 16px;
        line-height: 28px;
    }   
`

const Button = styled.button`
    all: unset;
    margin: 20px 0 0 20px;
    width: 150px;
    height: 35px;
    padding: 0 10px;
    color: #E4600A;
    border: 1px solid #E4600A;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    transition: 0.15s cubic-bezier(.42,.35,.21,1);

    &:hover {
        scale: 1.03;
        background-color: #E4600A;
        color: white;
    }

    &:active {
        scale: 0.98;
    }

    @media screen and (max-width: 768px) {
        width: 80%;
        margin: 20px auto;
    }
`

const JoinGuides = () => {
    return (
        <Container>
            <JoinGuidesTitle>Join Our Network of Expert Guides</JoinGuidesTitle>
            <JoinGuidesCaption>
                "Partner with Voyajee and showcase your services to a global audience. Connect with travelers and grow your business by offering authentic and unforgettable experiences."
            </JoinGuidesCaption>
            <Image>
                <img src={JoinGuidesImg} alt="JoinGuidesImg" />
            </Image>
            <JoinGuidesFeatures>
                <FeaturesCont>
                    <FeaturesIcon>
                        <img src={JoinUsIcon} alt="JoinUsIcon" />
                    </FeaturesIcon>
                    <FeaturesInfo>
                        <h4>Flexible Listing Options</h4>
                        <p>Set your own prices, availability, and package details to suit your business needs.</p>
                    </FeaturesInfo>
                </FeaturesCont>
                <FeaturesCont>
                    <FeaturesIcon>
                        <img src={JoinUsIcon} alt="JoinUsIcon" />
                    </FeaturesIcon>
                    <FeaturesInfo>
                        <h4>Reach More Customers</h4>
                        <p> Gain visibility among thousands of app users looking for local tours and experiences.</p>
                    </FeaturesInfo>
                </FeaturesCont>
                <FeaturesCont>
                    <FeaturesIcon>
                        <img src={JoinUsIcon} alt="JoinUsIcon" />
                    </FeaturesIcon>
                    <FeaturesInfo>
                        <h4>Seamless Management</h4>
                        <p>Manage bookings, communicate with customers, and track your earnings all within the app.</p>
                    </FeaturesInfo>
                </FeaturesCont>
                <FeaturesCont>
                    <FeaturesIcon>
                        <img src={JoinUsIcon} alt="JoinUsIcon" />
                    </FeaturesIcon>
                    <FeaturesInfo>
                        <h4>Build Your Brand</h4>
                        <p>Promote your expertise and unique offerings through a trusted platform that values local insights.</p>
                    </FeaturesInfo>
                </FeaturesCont>
                <FeaturesCont>
                    <FeaturesIcon>
                        <img src={JoinUsIcon} alt="JoinUsIcon" />
                    </FeaturesIcon>
                    <FeaturesInfo>
                        <h4>Support and Resources</h4>
                        <p>Access exclusive resources and customer support to help you deliver exceptional service and grow your business. </p>
                    </FeaturesInfo>
                </FeaturesCont>
            </JoinGuidesFeatures>
            <Button>
                Learn More
                <ArrowOutwardRoundedIcon style={{ fontSize: '20px', marginTop: '-1px' }} />
            </Button>
        </Container>
    )
}

export default JoinGuides