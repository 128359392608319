import React from 'react'
import styled from 'styled-components'

import introImg from '../../../Assets/Images/introImg.png'
import whyChooseUsImg from '../../../Assets/Images/whyChooseUsImg.png'
import ArrowOutwardRoundedIcon from '@mui/icons-material/ArrowOutwardRounded';
import ArrowDownwardRoundedIcon from '@mui/icons-material/ArrowDownwardRounded';

const Container = styled.div`
    width: 100%;
    min-height: 95svh;
    padding: 0 6vw;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media screen and (max-width: 768px) {
        min-height: auto;
        flex-direction: ${({ flexD }) => flexD === "column-reverse" ? "column-reverse" : "column"};
        margin: 40px 0 60px 0;
        justify-content: center;
        gap: 60px;
    }
`

const ImageCont = styled.div`
    position: relative;
    width: 45%;
    height: auto;
    padding: 0 30px;
    display: flex;
    justify-content: center;
    align-items: center;

    @media screen and (max-width: 768px) {
        width: 100%;
    }
    `
const InfoContentCont = styled.div`
    position: relative;
    width: calc(55% - 30px);
    height: auto;

    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 30px;

    @media screen and (max-width: 768px) {
        width: 100%;
    }
`

const IntroTitle = styled.h1`
    font-size: 40px;
    color: black;
    text-align: left;
    letter-spacing: -1px;
    font-family: 'Roboto', sans-serif;
    font-weight: 500;

    @media screen and (max-width: 768px) {
        text-align: center;
        font-size: 35px;
    }
`

const IntroText = styled.p`
    font-size: 16px;
    color: black;
    text-align: left;
    font-family: 'Roboto', sans-serif;
    font-weight: 300;
    line-height: 32px;

    @media screen and (max-width: 768px) {
        font-size: 16px;
        line-height: 28px;
        text-align: justify;
    }
`

const Button = styled.button`
    all: unset;
    width: 150px;
    height: 35px;
    padding: 0 10px;
    color: #E4600A;
    border: 1px solid #E4600A;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    transition: 0.15s cubic-bezier(.42,.35,.21,1);

    &:hover {
        scale: 1.03;
        background-color: #E4600A;
        color: white;
    }

    &:active {
        scale: 0.98;
    }

    @media screen and (max-width: 768px) {
        width: 80%;
        margin: 0 auto;
    }
`
const Intro = () => {
    return (
        <Container>
            <ImageCont>
                <img src={introImg} alt="introImg" />
            </ImageCont>
            <InfoContentCont>
                <IntroTitle>Explore, Experience, and Book Your<br />
                    Sri Lankan Adventure!</IntroTitle>
                <IntroText>
                    Voyajee is your all-in-one travel platform designed specifically for exploring the rich landscapes, cultural heritage, and adventures of Sri Lanka. Whether you're seeking hidden gems, curated tours,
                    or local experiences, Voyajee connects you with everything you need to plan and book your journey.
                    From interactive guides to instant booking features, Voyajee makes travel simple and accessible. Browse top destinations, discover unique excursions, and engage with local experts to make the most out of your trip—all in one app.
                </IntroText>
                <Button>
                    Learn More
                    <ArrowOutwardRoundedIcon style={{ fontSize: '20px', marginTop: '-1px' }} />
                </Button>
            </InfoContentCont>
        </Container>
    )
}

const WhyChooseUs = () => {
    return (
        <Container flexD="column-reverse" >
            <InfoContentCont>
                <IntroTitle>Why Choose Voyajee?</IntroTitle>
                <IntroText>
                    Voyajee is designed to transform the way you explore and experience Sri Lanka. By providing real-time access to curated destinations, local experiences, and instant booking options, Voyajee simplifies your travel planning and ensures a seamless adventure every step of the way. Discover handpicked destinations and hidden gems, book tours and activities instantly, and connect with local guides who offer authentic insights and personalized experiences. Whether you're seeking cultural immersion, beach relaxation, or wildlife adventures, Voyajee has something for every traveler.
                </IntroText>
                <Button>
                    Download App
                    <ArrowDownwardRoundedIcon style={{ fontSize: '20px', marginTop: '-1px' }} />
                </Button>
            </InfoContentCont>
            <ImageCont>
                <img src={whyChooseUsImg} alt="whyChooseUsImg" />
            </ImageCont>
        </Container>
    )
}

const Divider = styled.div`
    width: 90%;
    height: 1px;
    margin: 0 auto;
    background-color: #E6E6E6;
`

export { Intro, WhyChooseUs, Divider }